.home {
  width: 100%;
  overflow-x: hidden;
  background: var(--color-background);
  color: var(--color-text);
}

.hero {
  min-height: 100vh;
  background: linear-gradient(135deg, #F7FAFC 0%, #EDF2F7 100%);
  color: var(--color-text);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.hero-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: var(--color-primary);
  line-height: 1.2;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: var(--color-text-light);
  line-height: 1.6;
}

.video-placeholder {
  width: 100%;
  max-width: 800px;
  margin: 3rem auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.video-container {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
}

.video-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* or 'cover' if you want it to fill the container */
  background-color: white; /* optional: adds a background if the gif has transparency */
}

.features {
  padding: 6rem 0;
  background: var(--color-background);
  width: 100%;
}

.features h2 {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 2.5rem;
  color: var(--color-primary);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-card {
  text-align: center;
  padding: 2.5rem;
  border-radius: 12px;
  background: var(--color-card-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid var(--color-border);
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.feature-card i {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: var(--color-accent);
}

.feature-card h3 {
  margin-bottom: 1rem;
  color: var(--color-primary);
  font-size: 1.5rem;
}

.feature-card p {
  color: var(--color-text-light);
  line-height: 1.6;
}

.cta {
  background: var(--color-background);
  padding: 6rem 0;
  width: 100%;
  border-top: 1px solid var(--color-border);
}

.cta-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.cta h2 {
  margin-bottom: 2rem;
  color: var(--color-primary);
  font-size: 2.5rem;
  text-align: center;
}

.cta-button {
  background: var(--color-accent);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  display: inline-block;
}

.cta-button:hover {
  background: #0051BC;
  transform: translateY(-2px);
}

@media screen and (max-width: 768px) {
  .hero h1 {
    font-size: 2.2rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .features h2, .cta h2 {
    font-size: 2rem;
  }
} 