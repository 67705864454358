.footer {
  background: #F7FAFC;
  color: var(--color-text);
  padding: 4rem 20px 2rem;
  border-top: 1px solid var(--color-border);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
}

.footer-section h3 {
  color: var(--color-primary);
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.75rem;
}

.footer-section a {
  color: var(--color-text-light);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: var(--color-accent);
}

.social-links {
  margin-top: 1.5rem;
}

.social-links a {
  margin-right: 1.5rem;
  font-size: 1.5rem;
  color: var(--color-text-light);
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--color-accent);
}

.footer-bottom {
  margin-top: 3rem;
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid var(--color-border);
  color: var(--color-text-light);
}

@media screen and (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .social-links {
    justify-content: center;
    display: flex;
  }
} 