.navbar {
  background: var(--color-background);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
}

.menu-icon {
  display: none;
  color: var(--color-primary);
  font-size: 1.8rem;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-item {
  margin: 0 1rem;
}

.nav-link {
  color: var(--color-text);
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  font-weight: 500;
}

.nav-link:hover {
  color: var(--color-accent);
}

@media screen and (max-width: 960px) {
  .menu-icon {
    display: block;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: var(--color-background);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 1rem 0;
  }

  .nav-item {
    margin: 1rem 0;
  }

  .nav-link.login-button {
  background-color: #1877F2;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
}

.nav-link.login-button:hover {
  background-color: #166FE5;
}

.nav-link.login-button i {
  font-size: 1.1em;
}

@media screen and (max-width: 768px) {
  .nav-link.login-button {
    margin: 10px 0;
    justify-content: center;
  }
}
} 