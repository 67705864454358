.success-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(135deg, #F7FAFC 0%, #EDF2F7 100%);
}

.success-box {
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.success-icon {
  font-size: 4rem;
  color: #48BB78;
  margin-bottom: 1.5rem;
}

.success-box h1 {
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.success-box p {
  color: var(--color-text-light);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.contact-options {
  margin-top: 2rem;
}

.contact-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.messenger-btn, .whatsapp-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.2s ease;
}

.messenger-btn:hover, .whatsapp-btn:hover {
  transform: translateY(-2px);
}

.messenger-btn {
  background: #0084FF;
  color: white;
}

.whatsapp-btn {
  background: #25D366;
  color: white;
}