.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0px 0;
}

.logo-image {
  height: 80px;
  width: auto;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .logo-image {
    height: 80px; /* smaller on mobile if needed */
  }
}