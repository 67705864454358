:root {
  /* Main colors */
  --color-background: #FFFFFF;
  --color-primary: #2D3748;
  --color-secondary: #4A5568;
  --color-accent: #0070F3;
  
  /* Text colors */
  --color-text: #2D3748;
  --color-text-light: #4A5568;
  --color-text-white: #FFFFFF;
  
  /* UI elements */
  --color-border: #E2E8F0;
  --color-card-bg: #FFFFFF;
  --color-hover: #EDF2F7;
  
  /* Accent colors */
  --color-success: #48BB78;
  --color-error: #F56565;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  background: var(--color-background);
  color: var(--color-text);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

#root {
  width: 100%;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: var(--color-primary);
}

p, span, a, li, button {
  font-family: 'Roboto', sans-serif;
}
